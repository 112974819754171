import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { ResourceService } from 'src/app/global/services/resource.service';
import { WEB_API } from 'src/app/global/services/web-api';
import { Produto } from '../domain/produto.model';
import { Page } from '../domain/return-api.model';
import { ConciliacaoBancariaDoc } from '../domain/conciliacao-bancaria-doc.model';
import { ConciliacaoBancariaDocSerializer } from '../serializables/conciliacao-bancaria-doc.serializer';
import { ParcelaClienteDOCView } from '../domain/parcela-cliente-doc-view.model';
import { ParcelaChatbot } from '../domain/parcela-chatbot.model';
@Injectable()
export class ParcelaChatbotService extends ResourceService<ParcelaChatbot> {
  private static RESOURCE = 'parcela-chatbot';

  produto: Produto;
  produtoId: string;

  constructor(private http: HttpClient) {
    super(http, `${WEB_API}`, `${ParcelaChatbotService.RESOURCE}`, new ConciliacaoBancariaDocSerializer(ConciliacaoBancariaDoc));
  }

  findAllPageable(queryOptions: QueryOptions): Observable<Page<ParcelaChatbot>> {
    return this.http.get<Page<ParcelaChatbot>>(`${WEB_API}/${ParcelaChatbotService.RESOURCE}/pageable?${queryOptions.toQueryString()}`);
  }

  associar(data: {}): Observable<any> {
    return this.http.post<any>(`${WEB_API}/${ParcelaChatbotService.RESOURCE}/associar`, data);
  }

  getValorTotal(queryOptions: QueryOptions): Observable<any> {
    return this.http.get<number>(`${WEB_API}/${ParcelaChatbotService.RESOURCE}/get-valor-total?${queryOptions.toQueryString()}`);
  }

  vincular(dto: any): Observable<ParcelaChatbot> {
    return this.http.post<ParcelaChatbot>(`${WEB_API}/${ParcelaChatbotService.RESOURCE}/vincular-parcela`, dto);
  }
}
