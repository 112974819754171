import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { SharedService } from 'src/app/auth/shared.service';
import { Usuario } from 'src/app/base/domain/usuario.model';
import { Router } from '@angular/router';

// tslint:disable-next-line: no-any
declare const $: any;

// Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  children?: ChildrenItems[];
  profiles?: string[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
  profiles?: string[];
}

export const DIRETOR_ADMIN_PERMISSOES = ['ROLE_ADMIN', 'ROLE_DIRETOR'];
export const DIRETOR_PERMISSOES = ['ROLE_DIRETOR'];
export const GESTAO_PERMISSOES = ['ROLE_ADMIN', 'ROLE_GESTOR', 'ROLE_DIRETOR'];
export const AUDITORIA_PERMISSOES = ['ROLE_GESTOR', 'ROLE_DIRETOR'];
export const CAIXAS_PERMISSOES = ['ROLE_GESTOR', 'ROLE_DIRETOR'];
export const CONTRATOS_PERMISSOES = ['ROLE_GESTOR', 'ROLE_PRESTADOR', 'ROLE_DIRETOR', 'ROLE_RETAGUARDA'];
export const PARCELAS_PERMISSOES = ['ROLE_GESTOR', 'ROLE_PRESTADOR', 'ROLE_DIRETOR', 'ROLE_RETAGUARDA'];
export const CLIENTES_PERMISSOES = ['ROLE_GESTOR', 'ROLE_PRESTADOR', 'ROLE_DIRETOR'];
export const IMPORTACAO_PERMISSOES = ['ROLE_IMPORTACAO', 'ROLE_GESTOR', 'ROLE_DIRETOR', 'ROLE_PRESTADOR'];
export const CAPTACAO_PERMISSOES = ['ROLE_CAPTADOR', 'ROLE_GESTOR', 'ROLE_DIRETOR', 'ROLE_PRESTADOR'];
export const ACOMPANHAMENTO_COBRANCAS = ['ROLE_ADMIN', 'ROLE_RETAGUARDA', 'ROLE_FISCAL'];
export const RELATORIOS_PERMISSOES = ['ROLE_GESTOR', 'ROLE_DIRETOR', 'ROLE_FISCAL', 'ROLE_RETAGUARDA', 'ROLE_PRESTADOR'];
export const RELATORIOS_RETAGUARDA_PERMISSOES = ['ROLE_GESTOR', 'ROLE_DIRETOR', 'ROLE_RETAGUARDA'];
export const RELATORIOS_FISCAL_RETAGUARDA_PERMISSOES = ['ROLE_GESTOR', 'ROLE_DIRETOR', 'ROLE_FISCAL', 'ROLE_RETAGUARDA', 'ROLE_PRESTADOR'];

// Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: '/base/gestao/faturamento',
    title: 'Acomp. Faturamento',
    type: 'sub',
    icontype: 'dashboard',
    profiles: ['ROLE_ADMIN'],
    collapse: 'Faturamento',
    children: [
      { path: 'acompanhamento-faturamento', title: 'Acomp. Faturamento', ab: 'ACF', profiles: ['ROLE_ADMIN'] },
      { path: 'acompanhamento-faturamento-digital', title: 'Faturamento Digital', ab: 'ACFD', profiles: ['ROLE_ADMIN'] },
      { path: 'acompanhamento-faturamento-nacional', title: 'Faturamento Nacional', ab: 'ACFD', profiles: ['ROLE_ADMIN'] },
      { path: 'acompanhamento-faturamento-boleto', title: 'Faturamento Boletos', ab: 'ACFB', profiles: ['ROLE_ADMIN'] },
    ],
  },
  {
    path: '/base/gestao/acompanhamento-prorrogado',
    title: 'Acomp. Prorrogados',
    type: 'link',
    icontype: 'dashboard',
    profiles: ['ROLE_ADMIN'],
  },
  {
    path: '/base/gestao/acompanhamento-cobranca',
    title: 'Acomp. de Cobrancas',
    type: 'sub',
    icontype: 'view_week',
    profiles: ACOMPANHAMENTO_COBRANCAS,
    collapse: 'Cobranças',
    children: [
      { path: 'geral-cobranca', title: 'Acomp. Geral', ab: 'ACG', profiles: ['ROLE_ADMIN'] },
      { path: 'turno-cobranca', title: 'Acomp. por Turno', ab: 'ACT', profiles: ['ROLE_ADMIN', 'ROLE_RETAGUARDA', 'ROLE_FISCAL'] },
      {
        path: 'dashboard-cobranca-semanal',
        title: 'Dash. Cob. Semanal',
        ab: 'ACT',
        profiles: ['ROLE_ADMIN', 'ROLE_RETAGUARDA', 'ROLE_FISCAL'],
      },
    ],
  },
  {
    path: '/base/gestao/crescimento-sustentavel',
    title: 'Cresc. Sustentavel',
    type: 'link',
    icontype: 'equalizer',
    profiles: ['ROLE_ADMIN'],
  },
  {
    path: '/base/gestao/acompanhamento-despesas',
    title: 'Acomp. Despesas',
    type: 'link',
    icontype: 'equalizer',
    profiles: ['ROLE_ADMIN'],
  },
  {
    path: '/base/gestao',
    title: 'Análise & Gestão',
    type: 'sub',
    icontype: 'fact_check',
    collapse: 'Gestão',
    children: [
      { path: 'report-main', title: 'Relatórios', ab: 'R', profiles: RELATORIOS_FISCAL_RETAGUARDA_PERMISSOES },
      { path: 'acompanhamento-conferencia-caixa-geral', title: 'Acomp. Conferencia Caixa Geral', ab: 'ACGE', profiles: GESTAO_PERMISSOES },
      { path: 'acompanhamento-feriado', title: 'Acompanhamento de Feriados', ab: 'F', profiles: GESTAO_PERMISSOES },
      { path: 'acompanhamento-despesa-administrativa', title: 'Acompanhamento de Despesas', ab: 'ADA', profiles: GESTAO_PERMISSOES },
      { path: 'acompanhamento-transferencia-entre-postos', title: 'Complemento Entre Postos', ab: 'TEP', profiles: GESTAO_PERMISSOES },
      { path: 'acompanhamento-sangria-geral', title: 'Acomp. Sangrias Geral', ab: 'ASG', profiles: GESTAO_PERMISSOES },
      { path: 'acompanhamento-sangria-posto', title: 'Acomp. Sangria Posto', ab: 'ASP', profiles: GESTAO_PERMISSOES },
      { path: 'acompanhamento-sangria', title: 'Acomp. Sangrias Funcionário', ab: 'ASF', profiles: GESTAO_PERMISSOES },
      { path: 'acompanhamento-sobra', title: 'Acompanhamento de Sobras', ab: 'ASB', profiles: GESTAO_PERMISSOES },
      { path: 'acompanhamento-roubos', title: 'Acompanhamento de Roubos', ab: 'ACR', profiles: GESTAO_PERMISSOES },
      { path: 'contratacao-captacao', title: 'Contratação de Captações', ab: 'CC', profiles: GESTAO_PERMISSOES },
      { path: 'captados-visitados', title: 'Captados Visitados', ab: 'CPV', profiles: GESTAO_PERMISSOES },
      { path: 'motivo-contrato-cancelado', title: 'Motivos de Desistência de Contratos', ab: 'DDC', profiles: GESTAO_PERMISSOES },
      { path: 'pendencias-fechamento-caixa', title: 'Pendências Fechamento', ab: 'PFC', profiles: GESTAO_PERMISSOES },
      { path: 'equipes', title: 'Equipes', ab: 'EQP', profiles: GESTAO_PERMISSOES },
      { path: 'categoria-plano-conta', title: 'Categorias Plano Conta', ab: 'CPC', profiles: ['ROLE_ADMIN'] },
      { path: 'caixas-nao-abertos', title: 'Caixas Não Abertos', ab: 'PCX', profiles: GESTAO_PERMISSOES },
      { path: 'parcela-duplicada', title: 'Parcela Duplicadas', ab: 'PD', profiles: GESTAO_PERMISSOES },
      { path: 'acompanhamento-vendas', title: 'Acompanhamento de Vendas', ab: 'AV', profiles: GESTAO_PERMISSOES },
      { path: 'aprovacao-de-credito', title: 'Aprovação de Crédito', ab: 'APCE', profiles: DIRETOR_PERMISSOES },
      { path: 'realocar-funcionario', title: 'Realocar Funcionario', ab: 'RF', profiles: GESTAO_PERMISSOES },
      { path: 'operadores-externos', title: 'Captador Externos', ab: 'OE', profiles: GESTAO_PERMISSOES },
      { path: 'vendedores-externos', title: 'Vendedores Externos', ab: 'VE', profiles: GESTAO_PERMISSOES },
      { path: 'monitoramento-de-repasses', title: 'Monitoramento de Repasses', ab: 'MR', profiles: GESTAO_PERMISSOES },
      { path: 'monitoramento-lancamento-entrada', title: 'Monitoramento Lan. de Entrada', ab: 'MLE', profiles: GESTAO_PERMISSOES },
      { path: 'monitoramento-repasse-tipo-cliente', title: 'Monit. Repasse Tipo Cliente', ab: 'MRC', profiles: GESTAO_PERMISSOES },
      { path: 'cobranca-cliente-regular-fds', title: 'Cliente Regular FDS', ab: 'CRFDS', profiles: GESTAO_PERMISSOES },
      {
        path: 'acompanhamento-comprovantes-pix',
        title: 'Acomp. de Comprovantes',
        ab: 'ACOMP',
        profiles: ['ROLE_ADMIN', 'ROLE_PRESTADOR', 'ROLE_DIRETOR'],
      },
    ],
    profiles: RELATORIOS_PERMISSOES,
  },
  {
    path: '/base/caixas',
    title: 'Caixas',
    type: 'sub',
    icontype: 'local_atm',
    collapse: 'Caixas',
    children: [
      { path: 'controle', title: 'Controle', ab: 'CCT', profiles: CAIXAS_PERMISSOES },
      { path: 'cobranca', title: 'cobranca', ab: 'CCB', profiles: ['ROLE_ADMIN'] },
      { path: 'geral', title: 'Geral', ab: 'CGE', profiles: CAIXAS_PERMISSOES },
      { path: 'funcionarios', title: 'Funcionários', ab: 'CFU', profiles: CAIXAS_PERMISSOES },
      { path: 'caixa-despesa-funcionario', title: 'Despesa Funcionário', ab: 'CDEF', profiles: CAIXAS_PERMISSOES },
      { path: 'acompanhamento-pendencia', title: 'Pendências', ab: 'PND', profiles: CAIXAS_PERMISSOES },
    ],
    profiles: CAIXAS_PERMISSOES,
  },
  {
    path: '/base/contratos',
    title: 'Contratos',
    type: 'sub',
    icontype: 'list_alt',
    collapse: 'Contratos',
    children: [
      { path: 'acompanhamento-contrato', title: 'Acompanhamento', ab: 'A', profiles: CONTRATOS_PERMISSOES },
      { path: 'a-cancelar', title: 'A cancelar', ab: 'AC', profiles: CONTRATOS_PERMISSOES },
      { path: 'acompanhar-reagendamentos', title: 'Reagendamentos', ab: 'AR', profiles: CONTRATOS_PERMISSOES },
      { path: 'em-finalizacao', title: 'Em Renovação', ab: 'RV', profiles: CONTRATOS_PERMISSOES },
      { path: 'debitos-funcionarios', title: 'Débitos Funcionários', ab: 'DF', profiles: ['ROLE_ADMIN'] },
      { path: 'prorrogar', title: 'Prorrogação', ab: 'RE', profiles: DIRETOR_ADMIN_PERMISSOES },
    ],
    profiles: CONTRATOS_PERMISSOES,
  },
  {
    path: '/base/clientes',
    title: 'Clientes',
    type: 'sub',
    icontype: 'face',
    collapse: 'clientes',
    children: [
      { path: 'acompanhamento', title: 'Acompanhamento', ab: 'AC', profiles: CLIENTES_PERMISSOES },
      { path: 'planejamento', title: 'Planejamento', ab: 'PL', profiles: CLIENTES_PERMISSOES },
      { path: 'repasses', title: 'Liberados para Repasse', ab: 'R', profiles: CLIENTES_PERMISSOES },
      { path: 'inativos', title: 'Clientes Inativos', ab: 'CI', profiles: CLIENTES_PERMISSOES },
      { path: 'analise-de-risco', title: 'Análise de Risco', ab: 'AR', profiles: CLIENTES_PERMISSOES },
      { path: 'acoes-de-venda', title: 'Ações de Venda', ab: 'AV', profiles: CLIENTES_PERMISSOES },
      { path: 'potenciais-clientes', title: 'Potenciais Clientes', ab: 'PC', profiles: CLIENTES_PERMISSOES },
      { path: 'verificacao-clientes', title: 'Integração Inovamind', ab: 'IINV', profiles: CLIENTES_PERMISSOES },
      { path: 'cliente-cobranca-judicial', title: 'Clientes Cobranca Judicial', ab: 'CCJ', profiles: CLIENTES_PERMISSOES },
      { path: 'cobranca-judicial', title: 'Cobranca Judicial', ab: 'CJ', profiles: CLIENTES_PERMISSOES },
    ],
    profiles: CLIENTES_PERMISSOES,
  },
  {
    path: '/base/auditorias',
    title: 'Auditorias',
    type: 'sub',
    icontype: 'account_tree',
    collapse: 'auditoria',
    children: [
      { path: 'auditoria-alterar-parcela', title: 'Alteracao de parcelas', ab: 'AAP', profiles: AUDITORIA_PERMISSOES },
      { path: 'contrato', title: 'Contrato', ab: 'AC', profiles: AUDITORIA_PERMISSOES },
      { path: 'despesa', title: 'Despesa', ab: 'AD', profiles: AUDITORIA_PERMISSOES },
    ],
    profiles: AUDITORIA_PERMISSOES,
  },
  {
    path: '/base/conciliacao',
    title: 'Conciliação',
    type: 'sub',
    icontype: 'local_atm',
    collapse: 'conciliacao',
    children: [
      { path: 'conciliacao-bancaria', title: 'Conciliação Bancaria', ab: 'CB', profiles: DIRETOR_ADMIN_PERMISSOES },
      { path: 'visualizar-conciliacao', title: 'Visualizar Conciliação', ab: 'VCB', profiles: DIRETOR_ADMIN_PERMISSOES },
      { path: 'arquivo-extrato', title: 'Arquivo Extrato', ab: 'AE', profiles: DIRETOR_ADMIN_PERMISSOES },
      { path: 'visualizar-conciliacao-ted', title: 'Visualizar Conciliação TED', ab: 'VTED', profiles: DIRETOR_ADMIN_PERMISSOES },
      { path: 'visualizar-conciliacao-doc', title: 'Visualizar Conciliação DOC', ab: 'VDOC', profiles: DIRETOR_ADMIN_PERMISSOES },
      { path: 'visualizar-conciliacao-tev', title: 'Visualizar Conciliação TEV', ab: 'VTEV', profiles: DIRETOR_ADMIN_PERMISSOES },
      { path: 'visualizar-conciliacao-dinheiro', title: 'Visualizar Conciliação Dinheiro', ab: 'VDIN', profiles: DIRETOR_ADMIN_PERMISSOES },
      { path: 'conta-tev', title: 'Cadastrar Conta TEV', ab: 'CCTEV', profiles: DIRETOR_ADMIN_PERMISSOES },
      { path: 'dashboard-conciliacao', title: 'Dashboard Conciliação', ab: 'DC', profiles: DIRETOR_ADMIN_PERMISSOES },
    ],
    profiles: DIRETOR_ADMIN_PERMISSOES,
  },
  {
    path: '/base/exportacao',
    title: 'Exportação',
    type: 'sub',
    icontype: 'file_download',
    collapse: 'exportacao',
    children: [
      { path: 'titulo-novos', title: 'Titulos Novos', ab: 'TN', profiles: IMPORTACAO_PERMISSOES },
      { path: 'contas-encerradas', title: 'Titulos Finalizados', ab: 'TF', profiles: IMPORTACAO_PERMISSOES },
    ],
    profiles: IMPORTACAO_PERMISSOES,
  },
  {
    path: '/base/captacao',
    title: 'Captação',
    type: 'sub',
    icontype: 'fact_check',
    collapse: 'Captação',
    children: [
      { path: 'planejamento-semana-captacao', title: 'Planejamento Semana', ab: 'PR', profiles: GESTAO_PERMISSOES },
      {
        path: 'acompanhamento-captacao',
        title: 'Acompanhemento Captação',
        ab: 'AC',
        profiles: CAPTACAO_PERMISSOES,
      },
    ],
    profiles: CAPTACAO_PERMISSOES,
  },
  {
    path: '/base/parcela-chatbot',
    title: 'Parcela Chatbot',
    type: 'sub',
    icontype: 'wallet',
    collapse: 'parcela-chatbot',
    children: [{ path: 'list-parcela', title: 'Parcelas via Chatbot', ab: 'PC', profiles: PARCELAS_PERMISSOES }],
    profiles: PARCELAS_PERMISSOES,
  },
  {
    path: '/base/configuracao',
    title: 'Configurações',
    type: 'sub',
    icontype: 'build',
    collapse: 'configuracao',
    children: [
      { path: 'produto', title: 'Produto', ab: 'P' },
      { path: 'usuario', title: 'Usuário', ab: 'U' },
    ],
    profiles: [],
  },
];

@Component({
  selector: 'app-sidebar-cmp',
  templateUrl: 'sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  // tslint:disable-next-line: no-any
  public menuItems: any[];
  // tslint:disable-next-line: no-any
  ps: any;

  shared: SharedService;

  usuario: Usuario;

  isMobileMenu(): boolean {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  constructor(private router: Router) {
    this.shared = SharedService.getInstance();
  }

  ngOnInit(): void {
    // tslint:disable-next-line: no-any
    this.menuItems = ROUTES.filter((menuItem: any) => menuItem);
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
      this.ps = new PerfectScrollbar(elemSidebar);
    }

    const shared = SharedService.getInstance();
    this.usuario = shared.usuario;
  }
  updatePS(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      this.ps.update();
    }
  }
  isMac(): boolean {
    let bool = false;
    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
      bool = true;
    }
    return bool;
  }

  logout(): void {
    this.shared.logout();
    this.router.navigate(['/login']);
  }
}
