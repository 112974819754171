import { Resource } from 'src/app/global/domain/resource.model';

export class DashBoardFaturamento extends Resource {
  nomeEstado: string;

  descricaoPosto: string;

  percentualEntregue: number;

  percentualNaoEntregue: number;

  percentualReagendado: number;

  percentualCancelado: number;

  valorTotalEntregue: number;

  valorTotalNaoEntregue: number;

  valorTotalReagendado: number;

  valorTotalCancelado: number;

  valorCancelado: number;

  quantidadeEntregue: number;

  quantidadeNaoEntregue: number;

  quantidadeReagendado: number;

  quantidadeRevisado: number;

  quantidadeTotal: number;

  quantidadeCancelado: number;

  valorTotal: number;

  valorRevisado: number;

  valorLiquidoDezParcelasEntregue: number;

  valorLiquidoVinteParcelasEntregue: number;

  valorLiquidoTotalEntregue: number;

  valorRepactuado: number;

  valorLiquidoDezBoleto: number;

  valorLiquidoVinteBoleto: number;

  valorLiquidoDezEletro: number;

  valorLiquidoVinteEletro: number;

  valorLiquidoTotalEletroBoleto: number;
}
