import { NgModule } from '@angular/core';
import { ParcelaChatbotComponent } from './parcela-chatbot.component';
import { ComponentsModule } from 'src/app/global/components/components.module';
import { CommonModule } from '@angular/common';
import { GlobalModule } from 'src/app/global/global.module';
import { PaginatorModule, TableModule } from 'primeng';
import { ParcelaChatbotService } from '../services/parcela-chatbot.service';

@NgModule({
  declarations: [ParcelaChatbotComponent],
  imports: [CommonModule, GlobalModule, TableModule, PaginatorModule, ComponentsModule],
  providers: [ParcelaChatbotService],
})
export class ParcelaChatbotModule {}
