import { Component, OnInit } from '@angular/core';
import { ParcelaChatbot } from '../domain/parcela-chatbot.model';
import { QueryOptions } from 'src/app/global/domain/query.options';
import { Page } from '../domain/return-api.model';
import { ParcelaChatbotService } from '../services/parcela-chatbot.service';
import { AppState } from 'src/app/App.state';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from 'src/app/global/services/dialog.service';
import {
  ParcelaChatbotEvent,
  VisualizarParcelaChatbotFilterOptions,
} from 'src/app/global/lists/parcela-chatbot-list/parcela-chatbot-list.component';
import { ParcelaChatbotDTO } from '../domain/dto/parcela-chatbot.dto';

@Component({
  selector: 'app-parcela-chatbot',
  templateUrl: './parcela-chatbot.component.html',
  styleUrls: ['./parcela-chatbot.component.css'],
})
export class ParcelaChatbotComponent implements OnInit {
  private static BAIXAR_ACTION = 'Baixar Arquivo';
  private static VINCULAR_ACTION = 'Vincular Parcela';

  queryOptions = new QueryOptions({ pageSize: 25 });

  page: Page<ParcelaChatbot> = new Page();
  totalRecords: number = 0;

  loading: boolean = false;

  constructor(
    private service: ParcelaChatbotService,
    public appState: AppState,
    private dialog: MatDialog,
    private dialogService: DialogService,
  ) {
    const date = new Date();
    date.setHours(0, 0, 0);
    this.queryOptions.params = {
      dataInicio: date.getTime(),
      dataFim: new Date().getTime(),
    };
  }

  ngOnInit(): void {}

  list(pageNumber: number = 0): void {
    this.loading = true;
    this.appState.isLoading = true;
    this.queryOptions.pageNumber = pageNumber + 1;

    const dataInicio: Date = new Date(this.queryOptions.params.dataInicio as number);
    dataInicio.setHours(0, 0, 0);
    const dataFim = new Date(this.queryOptions.params.dataFim as number);
    dataFim.setHours(23, 59, 59);

    this.queryOptions.params = {
      dataInicio: dataInicio.getTime(),
      dataFim: dataFim.getTime(),
    };

    this.service.findAllPageable(this.queryOptions).subscribe((response: Page<ParcelaChatbot>) => {
      this.loading = false;
      this.appState.isLoading = false;

      this.totalRecords = response.totalElements;

      response.content = this.mapearAcoes(response.content);
      this.page = response;
    });
  }

  mapearAcoes(parcelas: ParcelaChatbot[]): ParcelaChatbot[] {
    const mapearAcoes = (arquivo: ParcelaChatbot): ParcelaChatbot => {
      const acoes = [];
      acoes.push(ParcelaChatbotComponent.BAIXAR_ACTION);
      acoes.push(ParcelaChatbotComponent.VINCULAR_ACTION);
      return { ...arquivo, acoes };
    };

    return parcelas?.map(mapearAcoes) ?? [];
  }

  onFilter(options: VisualizarParcelaChatbotFilterOptions): void {
    const date = new Date();
    date.setHours(0, 0, 0, 0);

    this.queryOptions.params = {
      dataInicio: options.dataInicial ? options.dataInicial.getTime() : date.getTime(),
      dataFim: options.dataFinal ? options.dataFinal.getTime() : date.getTime(),
    };

    this.list();
  }

  async onAcaoClick(event: ParcelaChatbotEvent) {
    const actions = new Map<string, (parcelaChatbot: ParcelaChatbot) => void>()
      .set(ParcelaChatbotComponent.BAIXAR_ACTION, (parcelaChatbot: ParcelaChatbot) => {
        this.onDownload(parcelaChatbot);
      })
      .set(ParcelaChatbotComponent.VINCULAR_ACTION, (parcelaChatbot: ParcelaChatbot) => {
        this.onVincular(parcelaChatbot);
      });

    actions.get(event.acao)(event.parcelaChatbot);
  }

  async realizarAssociacao(parcelaChatbot: ParcelaChatbotEvent): Promise<void> {}

  onMenuAcaoClick(event: string) {}

  onDownload(parcelaChatbot: ParcelaChatbot): void {
    this.service.create(parcelaChatbot).subscribe(
      () => {
        this.appState.isLoading = false;
        this.dialogService.feedbackSuccess('Operação realizada com sucesso!');
        this.list();
      },
      // tslint:disable-next-line: typedef
      (error) => {
        this.appState.isLoading = false;
        console.error(error);
        this.dialogService.feedbackError('Ocorreu a baixar o arquivo!');
        this.list();
      },
    );
  }

  onVincular(parcelaChatbot: ParcelaChatbot): void {
    this.service.vincular(parcelaChatbot).subscribe(
      () => {
        this.appState.isLoading = false;
        this.dialogService.feedbackSuccess('Operação realizada com sucesso!');
        this.list();
      },
      // tslint:disable-next-line: typedef
      (error) => {
        this.appState.isLoading = false;
        this.dialogService.feedbackError('Ocorreu um erro ao vincular registro!');
        this.list();
      },
    );
  }

  onLoadArquivosPage(pageNumber: number): void {
    this.list(pageNumber);
  }
}
