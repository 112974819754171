import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ParcelaChatbot } from 'src/app/base/domain/parcela-chatbot.model';
import { Page } from 'src/app/base/domain/return-api.model';
import { QueryOptions } from '../../domain/query.options';
import { LazyLoadEvent } from 'primeng';

export interface VisualizarParcelaChatbotFilterOptions {
  dataInicial: Date;

  dataFinal: Date;
}

export interface ParcelaChatbotEvent {
  acao: string;
  parcelaChatbot: ParcelaChatbot;
}

@Component({
  selector: 'app-parcela-chatbot-list',
  templateUrl: './parcela-chatbot-list.component.html',
  styleUrls: ['./parcela-chatbot-list.component.css'],
})
export class ParcelaChatbotListComponent implements OnInit {
  @Output() filter = new EventEmitter<VisualizarParcelaChatbotFilterOptions>();
  @Output() loadArquivosPage = new EventEmitter<number>();
  @Output() acaoClick = new EventEmitter<ParcelaChatbotEvent>();
  @Output() menuAcaoClick = new EventEmitter<string>();
  @Output() novo = new EventEmitter<void>();

  filterOptions: VisualizarParcelaChatbotFilterOptions;
  private readonly defaultFilterOptions: VisualizarParcelaChatbotFilterOptions = {
    dataInicial: null,
    dataFinal: null,
  };

  @Input() page: Page<ParcelaChatbot>;
  @Input() totalRecords: number;
  @Input() rowsNumber: number;
  @Input() loading: boolean;
  @Input() valorTotal: number;

  private currentPage = 0;
  queryOptions = new QueryOptions();

  constructor() {}

  ngOnInit(): void {
    this.onLimparFiltros();
  }

  loadArquivos(event: LazyLoadEvent): void {
    const pageNumber = Math.floor(event.first / event.rows);
    this.currentPage = pageNumber;
    this.loadArquivosPage.emit(pageNumber);
  }

  onAtualizar(): void {
    this.loadArquivosPage.emit(this.currentPage);
  }

  onMenuAcaoClick(event: string): void {
    this.menuAcaoClick.emit(event);
  }

  onAcaoClick(acao: string, domain: ParcelaChatbot): void {
    this.acaoClick.emit({ acao, parcelaChatbot: domain });
  }

  onLimparFiltros(): void {
    this.filterOptions = Object.assign({}, this.defaultFilterOptions);
  }

  onAplicarFiltro(): void {
    this.filter.emit(this.filterOptions);
  }
}
