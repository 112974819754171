import { VisualizarConciliacaoBancariaComponent } from './conciliacao/visualizar-conciliacao-bancaria/visualizar-conciliacao-bancaria.component';
import { ConciliacaoMainComponent } from './conciliacao/conciliacao-main/conciliacao-main.component';
import { CrescimentoSustentavelMainComponent } from './gestao/crescimento-sustentavel/crescimento-sustentavel-main/crescimento-sustentavel-main.component';
import { OperadoresExternosMainComponent } from './gestao/operadores-externos/operadores-externos-main/operadores-externos-main.component';
// tslint:disable: max-line-length
import { Routes } from '@angular/router';
import { PessoaListComponent } from './pessoa/pessoa-list/pessoa-list.component';
import { CaixaControleMainComponent } from './caixas/caixa-controle/caixa-controle-main/caixa-controle-main.component';
import { CaixaControleMovimentacoesComponent } from './caixas/caixa-controle/caixa-controle-movimentacoes/caixa-controle-movimentacoes.component';
import { AcompanhamentoContratoMainComponent } from './contratos/acompanhamento-contrato/acompanhamento-contrato-main/acompanhamento-contrato-main.component';
import { AcompanhamentoContratoDetailComponent } from './contratos/acompanhamento-contrato/acompanhamento-contrato-detail/acompanhamento-contrato-detail.component';
import { AuthGuard } from '../auth/interceptors/auth.guard';
import { PlanejamentoClienteMainComponent } from './cliente/planejamento-cliente/planejamento-cliente-main/planejamento-cliente-main.component';
import { ContratosACancelarMainComponent } from './contratos/contratos-a-cancelar/contratos-a-cancelar-main/contratos-a-cancelar-main.component';
import { ContratacaoCaptacaoMainComponent } from './gestao/contratacao-captacao/contracao-captacao-main/contratacao-captacao-main.component';
import { AcompanhamentoClienteMainComponent } from './cliente/acompanhamento-cliente/acompanhamento-cliente-main/acompanhamento-cliente-main.component';
import { VisitacaoCaptacaoMainComponent } from './gestao/visitacao-captacao/visitacao-captacao-main/visitacao-captacao-main.component';
import { CaixaFuncionarioMainComponent } from './caixas/caixa-funcionario/caixa-funcionario-main/caixa-funcionario-main.component';
import { AcompanhamentoSangriaComponent } from './gestao/acompanhamento-sangria/acompanhamento-sangria-main/acompanhamento-sangria.component';
import { AcompanhamentoSangriaDetailComponent } from './gestao/acompanhamento-sangria/acompanhamento-sangria-detail/acompanhamento-sangria-detail.component';
import { ContratosACancelarDetailComponent } from './contratos/contratos-a-cancelar/contratos-a-cancelar-detail/contratos-a-cancelar-detail.component';
import { CaixaFuncionarioMovimentacoesComponent } from './caixas/caixa-funcionario/caixa-funcionario-movimentacoes/caixa-funcionario-movimentacoes.component';
import { AcompanhamentoFeriadoMainComponent } from './gestao/acompanhamento-feriado/acompanhamento-feriado-main/acompanhamento-feriado-main.component';
import { AcompanhamentoReagendamentoMainComponent } from './gestao/acompanhamento-reagendamento/acompanhamento-reagendamento-main/acompanhamento-reagendamento-main.component';
import { PendenciaFechamentoCaixaMainComponent } from './gestao/pendencia-fechamento-caixa/pendencia-fechamento-caixa-main/pendencia-fechamento-caixa-main.component';
import { CaixaGeralMainComponent } from './caixas/caixa-geral/caixa-geral-main/caixa-geral-main.component';
import { CaixaGeralMovimentacoesComponent } from './caixas/caixa-geral/caixa-geral-movimentacoes/caixa-geral-movimentacoes.component';
import { CaixaDespesaFuncionarioMainComponent } from './caixas/caixa-despesa-funcionario/caixa-despesa-funcionario-main/caixa-despesa-funcionario-main.component';
import { CaixaDespesaFuncionarioMovimentacoesComponent } from './caixas/caixa-despesa-funcionario/caixa-despesa-funcionario-movimentacoes/caixa-despesa-funcionario-movimentacoes.component';
import { AcompanhamentoPendenciaMainComponent } from './caixas/acompanhamento-pendencia/acompanhamento-pendencia-main/acompanhamento-pendencia-main.component';
import { AuditoriaAlterarParcelaMainComponent } from './auditorias/auditoria-alterar-parcela-main/auditoria-alterar-parcela-main.component';
import { ClienteDetailComponent } from './cliente/acompanhamento-cliente/cliente-detail/cliente-detail.component';
import { AcompanhamentoSobraComponent } from './gestao/acompanhamento-sobra/acompanhamento-sobra-main/acompanhamento-sobra.component';
import { AuditoriaContratoMainComponent } from './auditorias/auditoria-contrato/auditoria-contrato-main/auditoria-contrato-main.component';
import { AuditoriaDespesaMainComponent } from './auditorias/auditoria-despesa/auditoria-despesa-main/auditoria-despesa-main.component';
import { EquipesMainComponent } from './gestao/equipes/equipes-main/equipes-main.component';
import { AcompanhamentoCasosRouboMainComponent } from './gestao/acompanhamento-casos-roubo/acompanhamento-casos-roubo-main/acompanhamento-casos-roubo-main.component';
import { EquipesDetailComponent } from './gestao/equipes/equipes-detail/equipes-detail.component';
import { CategoriaPlanoContaMainComponent } from './gestao/categoria-plano-conta/categoria-plano-conta-main/categoria-plano-conta-main.component';
import { AcompanhamentoDespesaAdministrativaMainComponent } from './gestao/acompanhamento-despesa-administrativa/acompanhamento-despesa-administrativa-main/acompanhamento-despesa-administrativa-main.component';
import { AcompanhamentoTransferenciaEntrePostosMainComponent } from './gestao/acompanhamento-transferencia-entre-postos/acompanhamento-transferencia-entre-postos-main/acompanhamento-transferencia-entre-postos-main.component';
import { AcompanhamentoCaixasPostosMainComponent } from './gestao/acompanhamento-caixas-postos/acompanhamento-caixas-postos-main/acompanhamento-caixas-postos-main.component';
import { AcompanhamentoSangriaPostoMainComponent } from './gestao/acompanhamento-sangria-posto/acompanhamento-sangria-posto-main/acompanhamento-sangria-posto-main.component';
import { ProdutoMainComponent } from './configuracao/produto/produto-main/produto-main.component';
import { UsuarioMainComponent } from './configuracao/usuario/usuario-main/usuario-main.component';
import { AcompanhamentoRepassesPeriodoMainComponent } from './cliente/acompanhamentos-repasses-periodo/acompanhamento-repasses-periodo-main/acompanhamento-repasses-periodo-main.component';
import { ContratosEmFinalizacaoMainComponent } from './contratos/contratos-em-finalizacao/contrato-em-finalizacao-main/contratos-em-finalizacao-main.component';
import { ContratoEmFinalizacaoDetailComponent } from './contratos/contratos-em-finalizacao/contrato-em-finalizacao-detail/contrato-em-finalizacao-detail.component';
import { ClientesInativosMainComponent } from './cliente/clientes-inativos/clientes-inativos-main/clientes-inativos-main.component';
import { ClientesInativosDetailComponent } from './cliente/clientes-inativos/clientes-inativos-detail/clientes-inativos-detail.component';
import { TitulosNovosContratosComponent } from './integracao-rbm/titulos-novos/titulos-novos-contratos/titulos-novos-contratos.component';
import { TitulosNovosArquivosComponent } from './integracao-rbm/titulos-novos/titulos-novos-arquivo/titulos-novos-arquivo.component';
import { ContasEncerradasContratosComponent } from './integracao-rbm/contas-encerradas/contas-encerradas-contratos/contas-encerradas-contratos.component';
import { TitulosNovosArquivosDetailComponent } from './integracao-rbm/titulos-novos/titulos-novos-arquivo-detail/titulos-novos-arquivo-detail.component';
import { ContasEncerradasArquivosComponent } from './integracao-rbm/contas-encerradas/contas-encerradas-arquivo/contas-encerradas-arquivo.component';
import { ContasEncerradasArquivosDetailComponent } from './integracao-rbm/contas-encerradas/contas-encerradas-arquivo-detail/contas-encerradas-arquivo-detail.component';
import { ArquivoAnaliseDeRiscoMainComponent } from './cliente/arquivo-analise-de-risco/arquivo-analise-de-risco-main/arquivo-analise-de-risco-main.component';
import { AnaliseDeRiscoClienteMainComponent } from './cliente/analise-de-risco-cliente/analise-de-risco-cliente-main/analise-de-risco-cliente-main.component';
import { AcompanhamentoParcelaDuplicadaMainComponent } from './gestao/acompanhamento-parcela-duplicada/acompanhamento-parcela-duplicada-main/acompanhamento-parcela-duplicada-main.component';
import { AcompanhamentoSangriaGeralMainComponent } from './gestao/acompanhamento-sangria-geral/acompanhamento-sangria-geral-main/acompanhamento-sangria-geral-main.component';
import { AcompanhamentoSangriaGeralDetailComponent } from './gestao/acompanhamento-sangria-geral/acompanhamento-sangria-geral-detail/acompanhamento-sangria-geral-detail.component';
import { ReportMainComponent } from './report/report-main/report-main.component';
import { AcompanhamentoConferenciaCaixaGeralMainComponent } from './gestao/acompanhamento-conferencia-caixa-geral/acompanhamento-conferencia-caixa-geral-main/acompanhamento-conferencia-caixa-geral-main.component';
import { AcompanhamentoFaturamentoMainComponent } from './gestao/acompanhamento-faturamento/acompanhamento-faturamento-main/acompanhamento-faturamento-main.component';
import { AcompanhamentoEstadoFaturamentoComponent } from './gestao/acompanhamento-faturamento/acompanhamento-estado-faturamento/acompanhamento-estado-faturamento.component';
import { AcompanhamentoPostoFaturamentoComponent } from './gestao/acompanhamento-faturamento/acompanhamento-posto-faturamento/acompanhamento-posto-faturamento.component';
import { AcompanhamentoVendaMainComponent } from './gestao/acompanhamento-venda/acompanhamento-venda-main/acompanhamento-venda-main.component';
import { MotivoContratoCanceladoMainComponent } from './gestao/motivo-contrato-cancelado/motivo-contrato-cancelado-main/motivo-contrato-cancelado-main.component';
import { AprovacaoDeCreditoMainComponent } from './gestao/aprovacao-de-credito/aprovacao-de-credito-main/aprovacao-de-credito-main.component';
import { RealocarFuncionarioMainComponent } from './gestao/realocar-funcionario/realocar-funcionario-main/realocar-funcionario-main.component';
import { AcoesDeVendaMainComponent } from './cliente/acoes-de-venda/acoes-de-venda-main/acoes-de-venda.component';
import { PotencialClienteMainComponent } from './cliente/potencial-cliente/potencial-cliente-main/potencial-cliente-main.component';
import { PlanejamentoSemanaCaptacaoMainComponent } from './captacao/planejamento-semana-captacao/planejamento-semana-captacao-main/planejamento-semana-captacao-main.component';
import { AcompanhamentoCaptacaoMainComponent } from './captacao/acompanhamento-captacao/acompanhamento-captacao-main/acompanhamento-captacao-main.component';
import { MonitoramentoRepassesMainComponent } from './gestao/monitoramento-repasses/monitoramento-repasses-main/monitoramento-repasses-main.component';
import { MonitoramentoLancamentoEntradaMainComponent } from './gestao/monitoramento-lancamento-entrada/monitoramento-lancamento-entrada-main/monitoramento-lancamento-entrada-main.component';
import { MonitoramentoRepasseTipoClienteMainComponent } from './gestao/monitoramento-repasse-tipo-cliente/monitoramento-repasse-tipo-cliente-main/monitoramento-repasse-tipo-cliente-main.component';
import { AcompanhamentoCobrancaMainComponent } from './gestao/acompanhamento-cobranca/acompanhamento-cobranca-main/acompanhamento-cobranca-main.component';
import { AcompanhamentoEstadoCobrancaComponent } from './gestao/acompanhamento-cobranca/acompanhamento-estado-cobranca/acompanhamento-estado-cobranca.component';
import { AcompanhamentoPostoCobrancaComponent } from './gestao/acompanhamento-cobranca/acompanhamento-posto-cobranca/acompanhamento-posto-cobranca.component';
import { CrescimentoSustentavelEstadoComponent } from './gestao/crescimento-sustentavel/crescimento-sustentavel-estado/crescimento-sustentavel-estado.component';
import { CrescimentoSustentavelPostoComponent } from './gestao/crescimento-sustentavel/crescimento-sustentavel-posto/crescimento-sustentavel-posto.component';
import { CobrancaClienteRegularFdsMainComponent } from './gestao/cobranca-cliente-regular-fds/cobranca-cliente-regular-fds-main/cobranca-cliente-regular-fds-main.component';
import { AcompanhamentoVendedorExternoMainComponent } from './gestao/acompanhamento-vendedor/acompanhamento-vendedor-externo-main/acompanhamento-vendedor-externo-main.component';
import { AcompanhamentoVendedorExternoDetailComponent } from './gestao/acompanhamento-vendedor/acompanhamento-vendedor-detail/acompanhamento-vendedor-detail.component';
import { AcompanhamentoClienteCarteiraMainComponent } from './gestao/acompanhamento-vendedor/acompanhamento-cliente-carteira-main/acompanhamento-cliente-carteira-main.component';
import { CaixaCobrancaMainComponent } from './caixas/caixa-cobranca/caixa-cobranca-main/caixa-cobranca-main.component';
import { AcompanhamentoCobrancaTurnoNacionalComponent } from './gestao/acompanhamento-cobranca/acompanhamento-cobranca-turno/acompanhamento-cobranca-turno-nacional/acompanhamento-cobranca-turno-nacional.component';
import { AcompanhamentoCobrancaTurnoEstadualComponent } from './gestao/acompanhamento-cobranca/acompanhamento-cobranca-turno/acompanhamento-cobranca-turno-estadual/acompanhamento-cobranca-turno-estadual.component';
import { AcompanhamentoCobrancaTurnoRotaComponent } from './gestao/acompanhamento-cobranca/acompanhamento-cobranca-turno/acompanhamento-cobranca-turno-rota/acompanhamento-cobranca-turno-rota.component';
import { VerificacaoClienteMainComponent } from './cliente/verificacao-cliente/verificacao-cliente-main/verificacao-cliente-main.component';
import { DashboardCobrancaSemanalComponent } from './gestao/acompanhamento-cobranca/dashboard-cobranca-semanal/dashboard-cobranca-semanal.component';
import { ClienteCobrancaJudicialMainComponent } from './cliente/cliente-cobranca-judicial/cliente-cobranca-judicial-main/cliente-cobranca-judicial-main.component';
import { CobrancaJudicialMainComponent } from './cliente/cobranca-judicial/cobranca-judicial-main/cobranca-judicial-main.component';
import { CobrancaJudicialDetailComponent } from './cliente/cobranca-judicial/cobranca-judicial-detail/cobranca-judicial-detail.component';
import { FaturamentoDigitalMainComponent } from './gestao/acompanhamento-faturamento-digital/faturamento-digital-main/faturamento-digital-main.component';
import { EstadoFaturamentoDigitalComponent } from './gestao/acompanhamento-faturamento-digital/estado-faturamento-digital/estado-faturamento-digital.component';
import { FaturamentoNacionalMainComponent } from './gestao/acompanhamento-faturamento-nacional/faturamento-nacional-main/faturamento-nacional-main.component';
import { EstadoFaturamentoNacionalComponent } from './gestao/acompanhamento-faturamento-nacional/estado-faturamento-nacional/estado-faturamento-nacional.component';
import { PostoFaturamentoNacionalComponent } from './gestao/acompanhamento-faturamento-nacional/posto-faturamento-nacional/posto-faturamento-nacional.component';
import { AcompanhamentoProrrogadoMainComponent } from './gestao/acompanhamento-prorrogado/acompanhamento-prorrogado-main/acompanhamento-prorrogado-main.component';
import { AcompanhamentoEstadoProrrogadoComponent } from './gestao/acompanhamento-prorrogado/acompanhamento-estado-prorrogado/acompanhamento-estado-prorrogado.component';
import { AcompanhamentoPostoProrrogadoComponent } from './gestao/acompanhamento-prorrogado/acompanhamento-posto-prorrogado/acompanhamento-posto-prorrogado.component';
import { PostoFaturamentoDigitalComponent } from './gestao/acompanhamento-faturamento-digital/posto-faturamento-digital/posto-faturamento-digital.component';
import { AcompanhamentoDespesaMainComponent } from './gestao/acompanhamento-despesa/acompanhamento-despesa-main/acompanhamento-despesa-main.component';
import { AcompanhamentoEstadoDespesasComponent } from './gestao/acompanhamento-despesa/acompanhamento-estado-despesa/acompanhamento-estado-despesa.component';
import { AcompanhamentoPostoDespesaComponent } from './gestao/acompanhamento-despesa/acompanhamento-posto-despesa/acompanhamento-posto-despesa.component';
import { AcompanhamentoComprovantesPixMainComponent } from './gestao/acompanhamento-comprovantes-pix/acompanhamento-comprovantes-pix-main/acompanhamento-comprovantes-pix-main.component';
import { DebitosFuncionarioMainComponent } from './contratos/debitos-funcionario/debitos-funcionario-main/debitos-funcionario-main.component';
import { DebitosFuncionarioDetailComponent } from './contratos/debitos-funcionario/debitos-funcionario-detail/debitos-funcionario-detail.component';
import { ContratosRenegociarMainComponent } from './contratos/contratos-renegociar/contratos-renegociar-main/contratos-renegociar-main.component';
import { ArquivoExtratoMainComponent } from './arquivo-extrato/arquivo-extrato-main/arquivo-extrato-main.component';
import { ConciliacaoDocComponent } from './conciliacao/conciliacao-doc/conciliacao-doc.component';
import { ConciliacaoTevComponent } from './conciliacao/conciliacao-tev/conciliacao-tev.component';
import { ContaTevComponent } from './conciliacao/conta-tev/conta-tev.component';
import { ConciliacaoTedComponent } from './conciliacao/conciliacao-ted/conciliacao-ted.component';
import { DashboardConciliacaoComponent } from './conciliacao/dashboard-conciliacao/dashboard-conciliacao.component';
import { ConciliacaoDinheiroComponent } from './conciliacao/conciliacao-dinheiro/conciliacao-dinheiro.component';
import { FaturamentoBoletoMainComponent } from './gestao/acompanhamento-faturamento-boleto/faturamento-boleto-main/faturamento-boleto-main.component';
import { FaturamentoBoletoEstadoComponent } from './gestao/acompanhamento-faturamento-boleto/faturamento-boleto-estado/faturamento-boleto-estado.component';
import { FaturamentoBoletoPostoComponent } from './gestao/acompanhamento-faturamento-boleto/faturamento-boleto-posto/faturamento-boleto-posto.component';
import { ParcelaChatbotComponent } from './parcela-chatbot/parcela-chatbot.component';

export const BaseRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'pessoa',
        component: PessoaListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'configuracao',
        children: [
          {
            path: 'produto',
            component: ProdutoMainComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'usuario',
            component: UsuarioMainComponent,
            children: [],
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'gestao',
        children: [
          {
            path: 'report-main',
            component: ReportMainComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'acompanhamento-conferencia-caixa-geral',
            component: AcompanhamentoConferenciaCaixaGeralMainComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'acompanhamento-feriado',
            component: AcompanhamentoFeriadoMainComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'acompanhamento-sangria',
            component: AcompanhamentoSangriaComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'acompanhamento-sangria/:id',
            component: AcompanhamentoSangriaDetailComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'contratacao-captacao',
            component: ContratacaoCaptacaoMainComponent,
          },
          {
            path: 'captados-visitados',
            component: VisitacaoCaptacaoMainComponent,
          },
          {
            path: 'pendencias-fechamento-caixa',
            component: PendenciaFechamentoCaixaMainComponent,
          },
          {
            path: 'acompanhamento-despesa-administrativa',
            component: AcompanhamentoDespesaAdministrativaMainComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'acompanhamento-transferencia-entre-postos',
            component: AcompanhamentoTransferenciaEntrePostosMainComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'acompanhamento-sangria-geral',
            component: AcompanhamentoSangriaGeralMainComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'acompanhamento-sangria-geral/:id',
            component: AcompanhamentoSangriaGeralDetailComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'acompanhamento-sangria-posto',
            component: AcompanhamentoSangriaPostoMainComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'acompanhamento-sobra',
            component: AcompanhamentoSobraComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'acompanhamento-roubos',
            component: AcompanhamentoCasosRouboMainComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'equipes',
            component: EquipesMainComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'equipe-detail/:id',
            component: EquipesDetailComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'categoria-plano-conta',
            component: CategoriaPlanoContaMainComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'caixas-nao-abertos',
            component: AcompanhamentoCaixasPostosMainComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'parcela-duplicada',
            component: AcompanhamentoParcelaDuplicadaMainComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'faturamento',
            children: [
              {
                path: 'acompanhamento-faturamento',
                component: AcompanhamentoFaturamentoMainComponent,
                children: [],
                canActivate: [AuthGuard],
              },
              {
                path: 'acompanhamento-estado-faturamento/:id',
                component: AcompanhamentoEstadoFaturamentoComponent,
                children: [],
                canActivate: [AuthGuard],
              },
              {
                path: 'acompanhamento-posto-faturamento/:id',
                component: AcompanhamentoPostoFaturamentoComponent,
                children: [],
                canActivate: [AuthGuard],
              },
              {
                path: 'acompanhamento-faturamento-boleto',
                component: FaturamentoBoletoMainComponent,
                children: [],
                canActivate: [AuthGuard],
              },
              {
                path: 'estado-faturamento-boleto/:id',
                component: FaturamentoBoletoEstadoComponent,
                children: [],
                canActivate: [AuthGuard],
              },
              {
                path: 'posto-faturamento-boleto/:id',
                component: FaturamentoBoletoPostoComponent,
                children: [],
                canActivate: [AuthGuard],
              },
              {
                path: 'acompanhamento-faturamento-digital',
                component: FaturamentoDigitalMainComponent,
                children: [],
                canActivate: [AuthGuard],
              },
              {
                path: 'estado-faturamento-digital/:id',
                component: EstadoFaturamentoDigitalComponent,
                children: [],
                canActivate: [AuthGuard],
              },
              {
                path: 'posto-faturamento-digital/:id',
                component: PostoFaturamentoDigitalComponent,
                children: [],
                canActivate: [AuthGuard],
              },

              {
                path: 'acompanhamento-faturamento-nacional',
                component: FaturamentoNacionalMainComponent,
                children: [],
                canActivate: [AuthGuard],
              },
              {
                path: 'estado-faturamento-nacional/:id',
                component: EstadoFaturamentoNacionalComponent,
                children: [],
                canActivate: [AuthGuard],
              },
              {
                path: 'posto-faturamento-nacional/:id',
                component: PostoFaturamentoNacionalComponent,
                children: [],
                canActivate: [AuthGuard],
              },
            ],
          },
          {
            path: 'acompanhamento-prorrogado',
            component: AcompanhamentoProrrogadoMainComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'acompanhamento-estado-prorrogado/:id',
            component: AcompanhamentoEstadoProrrogadoComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'acompanhamento-posto-prorrogado/:id',
            component: AcompanhamentoPostoProrrogadoComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'acompanhamento-vendas',
            component: AcompanhamentoVendaMainComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'motivo-contrato-cancelado',
            component: MotivoContratoCanceladoMainComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'aprovacao-de-credito',
            component: AprovacaoDeCreditoMainComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'realocar-funcionario',
            component: RealocarFuncionarioMainComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'monitoramento-de-repasses',
            component: MonitoramentoRepassesMainComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'monitoramento-lancamento-entrada',
            component: MonitoramentoLancamentoEntradaMainComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'monitoramento-repasse-tipo-cliente',
            component: MonitoramentoRepasseTipoClienteMainComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'acompanhamento-cobranca',
            children: [
              {
                path: 'geral-cobranca',
                component: AcompanhamentoCobrancaMainComponent,
                canActivate: [AuthGuard],
              },
              {
                path: 'geral-cobranca/acompanhamento-estado-cobranca/:id',
                component: AcompanhamentoEstadoCobrancaComponent,
                children: [],
                canActivate: [AuthGuard],
              },
              {
                path: 'geral-cobranca/acompanhamento-posto-cobranca/:id',
                component: AcompanhamentoPostoCobrancaComponent,
                children: [],
                canActivate: [AuthGuard],
              },
              {
                path: 'turno-cobranca',
                component: AcompanhamentoCobrancaTurnoNacionalComponent,
                canActivate: [AuthGuard],
              },
              {
                path: 'dashboard-cobranca-semanal',
                component: DashboardCobrancaSemanalComponent,
                canActivate: [AuthGuard],
              },
              {
                path: 'turno-cobranca/:estadoId',
                component: AcompanhamentoCobrancaTurnoEstadualComponent,
                canActivate: [AuthGuard],
              },
              {
                path: 'turno-cobranca/:estadoId/:postoId/:rota',
                component: AcompanhamentoCobrancaTurnoRotaComponent,
                canActivate: [AuthGuard],
              },
            ],
          },
          {
            path: 'operadores-externos',
            component: OperadoresExternosMainComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'vendedores-externos',
            component: AcompanhamentoVendedorExternoMainComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'vendedores-externos/:id',
            component: AcompanhamentoVendedorExternoDetailComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'vendedores-externos/carteira-cliente/:id',
            component: AcompanhamentoClienteCarteiraMainComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'crescimento-sustentavel',
            component: CrescimentoSustentavelMainComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'crescimento-sustentavel-estado/:id',
            component: CrescimentoSustentavelEstadoComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'crescimento-sustentavel-posto/:estadoId/:id',
            component: CrescimentoSustentavelPostoComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'cobranca-cliente-regular-fds',
            component: CobrancaClienteRegularFdsMainComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'acompanhamento-despesas',
            component: AcompanhamentoDespesaMainComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'acompanhamento-estado-despesas/:id',
            component: AcompanhamentoEstadoDespesasComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'acompanhamento-posto-despesas/:id',
            component: AcompanhamentoPostoDespesaComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'acompanhamento-comprovantes-pix',
            component: AcompanhamentoComprovantesPixMainComponent,
            children: [],
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'caixas',
        children: [
          {
            path: 'controle',
            component: CaixaControleMainComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'controle-movimentacoes/:id',
            component: CaixaControleMovimentacoesComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'funcionarios',
            component: CaixaFuncionarioMainComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'funcionario-movimentacoes/:id',
            component: CaixaFuncionarioMovimentacoesComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'geral',
            component: CaixaGeralMainComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'caixa-despesa-funcionario',
            component: CaixaDespesaFuncionarioMainComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'geral-movimentacoes/:id',
            component: CaixaGeralMovimentacoesComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'caixa-despesa-funcionario-movimentacoes/:id',
            component: CaixaDespesaFuncionarioMovimentacoesComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'acompanhamento-pendencia',
            component: AcompanhamentoPendenciaMainComponent,
            children: [],
            canActivate: [AuthGuard],
          },
          {
            path: 'cobranca',
            component: CaixaCobrancaMainComponent,
            children: [],
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'contratos',
        children: [
          {
            path: 'acompanhamento-contrato',
            component: AcompanhamentoContratoMainComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'acompanhamento-contrato-detail/:id',
            component: AcompanhamentoContratoDetailComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'a-cancelar',
            component: ContratosACancelarMainComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'a-cancelar-detail/:id',
            component: ContratosACancelarDetailComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'em-finalizacao',
            component: ContratosEmFinalizacaoMainComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'em-finalizacao-detail/:id',
            component: ContratoEmFinalizacaoDetailComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'acompanhar-reagendamentos',
            component: AcompanhamentoReagendamentoMainComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'debitos-funcionarios',
            component: DebitosFuncionarioMainComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'debitos-funcionarios-detail/:id',
            component: DebitosFuncionarioDetailComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'prorrogar',
            component: ContratosRenegociarMainComponent,
            canActivate: [AuthGuard],
          },
        ],
        canActivate: [AuthGuard],
      },
      {
        path: 'clientes',
        canActivate: [AuthGuard],
        children: [
          {
            path: 'acompanhamento',
            component: AcompanhamentoClienteMainComponent,
          },
          {
            path: 'detail/:id',
            component: ClienteDetailComponent,
          },
          {
            path: 'planejamento',
            component: PlanejamentoClienteMainComponent,
          },
          {
            path: 'repasses',
            component: AcompanhamentoRepassesPeriodoMainComponent,
          },
          {
            path: 'inativos',
            component: ClientesInativosMainComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'inativos/detail/:id',
            component: ClientesInativosDetailComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'analise-de-risco',
            component: ArquivoAnaliseDeRiscoMainComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'analise-de-risco-clientes/:id',
            component: AnaliseDeRiscoClienteMainComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'acoes-de-venda',
            component: AcoesDeVendaMainComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'potenciais-clientes',
            component: PotencialClienteMainComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'verificacao-clientes',
            component: VerificacaoClienteMainComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'cliente-cobranca-judicial',
            component: ClienteCobrancaJudicialMainComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'cobranca-judicial',
            component: CobrancaJudicialMainComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'cobranca-judicial/detail/:id',
            component: CobrancaJudicialDetailComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'auditorias',
        children: [
          {
            path: 'contrato',
            component: AuditoriaContratoMainComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'despesa',
            component: AuditoriaDespesaMainComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'auditoria-alterar-parcela',
            component: AuditoriaAlterarParcelaMainComponent,
          },
        ],
      },
      {
        path: 'exportacao',
        children: [
          {
            path: 'titulo-novos',
            component: TitulosNovosContratosComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'titulo-novos-arquivos',
            component: TitulosNovosArquivosComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'titulo-novos-arquivos/:id',
            component: TitulosNovosArquivosDetailComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'contas-encerradas',
            component: ContasEncerradasContratosComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'contas-encerradas-arquivos',
            component: ContasEncerradasArquivosComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'contas-encerradas-arquivos/:id',
            component: ContasEncerradasArquivosDetailComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'captacao',
        children: [
          {
            path: 'planejamento-semana-captacao',
            component: PlanejamentoSemanaCaptacaoMainComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'acompanhamento-captacao',
            component: AcompanhamentoCaptacaoMainComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'conciliacao',
        children: [
          {
            path: 'conciliacao-bancaria',
            component: ConciliacaoMainComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'visualizar-conciliacao',
            component: VisualizarConciliacaoBancariaComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'arquivo-extrato',
            component: ArquivoExtratoMainComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'visualizar-conciliacao-doc',
            component: ConciliacaoDocComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'visualizar-conciliacao-tev',
            component: ConciliacaoTevComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'conta-tev',
            component: ContaTevComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'visualizar-conciliacao-ted',
            component: ConciliacaoTedComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'visualizar-conciliacao-dinheiro',
            component: ConciliacaoDinheiroComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'dashboard-conciliacao',
            component: DashboardConciliacaoComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'parcela-chatbot',
        children: [
          {
            path: 'list-parcela',
            component: ParcelaChatbotComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
    ],
  },
];
