import { FuncionarioFormComponent } from './forms/funcionario-form/funcionario-form.component';
import { FuncionarioListComponent } from './lists/funcionario-list/funcionario-list.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaixaListComponent } from './lists/caixa-list/caixa-list.component';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from './components/components.module';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { CaixaMovimentacoesComponent } from './widgets/caixa-movimentacoes/caixa-movimentacoes.component';
import { TransferenciaListComponent } from './lists/transferencia-list/transferencia-list.component';
import { TabViewModule } from 'primeng/tabview';
import { ConfirmacaoDialogComponent } from './components/confirmacao-dialog/confirmacao-dialog.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogService } from './services/dialog.service';
import { ContratoListComponent } from './lists/contrato-list/contrato-list.component';
import { ContratoInfoGeralComponent } from './widgets/contrato-info-geral/contrato-info-geral.component';
import { ClienteInfoGeralComponent } from './widgets/cliente-info-geral/cliente-info-geral.component';
import { ParcelaListComponent } from './lists/parcela-list/parcela-list.component';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { EnderecoFormComponent } from './forms/endereco-form/endereco-form.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { PostoService } from '../base/services/posto.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CancelarContratoFormComponent } from './forms/cancelar-contrato-form/cancelar-contrato-form.component';
import { GraficoVerticalComponent } from './charts/grafico-vertical/grafico-vertical.component';
import { ClienteListComponent } from './lists/cliente-list/cliente-list.component';
import { ClientePlanejamentoListComponent } from './lists/cliente-planejamento-list/cliente-planejamento-list.component';
import { GlobalFilterComponent } from './widgets/global-filter/global-filter.component';
import { CounterCardComponent } from './widgets/counter-card/counter-card.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS, DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { CaptadoListComponent } from './lists/captado-list/captado-list.component';
import { DesbloquearClienteFormComponent } from './forms/desbloquear-cliente-form/desbloquear-cliente-form.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { FeriadoListComponent } from './lists/feriado-list/feriado-list.component';
import { FeriadoFormComponent } from './forms/feriado-form/feriado-form.component';
import { DataValorFormComponent } from './forms/data-valor-form/data-valor-form.component';
import { RepactuacaoContratoFormComponent } from './forms/repactuacao-contrato-form/repactuacao-contrato-form.component';
import { LiquidarContratoFormComponent } from './forms/liquidar-contrato-form/liquidar-contrato-form.component';
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
import { CheckboxSelectFormComponent } from './forms/checkbox-select-form/checkbox-select-form.component';
import { PendenciaFechamentoListComponent } from './lists/pendencia-fechamento-list/pendencia-fechamento-list.component';
import { ConferirCaixaFormComponent } from './forms/conferir-caixa-form/conferir-caixa-form.component';
import { ReagendamentoListComponent } from './lists/reagendamento-list/reagendamento-list.component';
import { AuditoriaAlterarParcelaMainComponent } from '../base/auditorias/auditoria-alterar-parcela-main/auditoria-alterar-parcela-main.component';
import { AlterarParcelaListComponent } from './lists/alterar-parcela-list/alterar-parcela-list.component';
import { ContaClienteInfoComponent } from './widgets/conta-cliente-info/conta-cliente-info.component';
import { CancelarSobraFormComponent } from './forms/cancelar-sobra-form/cancelar-sobra-form.component';
import { EquipeRotaFormComponent } from './forms/equipe-rota-form/equipe-rota-form.component';
import { AuditoriaContratoListComponent } from './lists/auditoria-contrato-list/auditoria-contrato-list.component';
import { AuditoriaDespesaListComponent } from './lists/auditoria-despesa-list/auditoria-despesa-list.component';
import { EquipeListComponent } from './lists/equipe-list/equipe-list.component';
import { DespesaRouboFormComponent } from './forms/alterar-despesa-roubo-form/despesa-roubo-form.component';
import { RotaListComponent } from './lists/rota-list/rota-list.component';
import { CategoriaPlanoContaListComponent } from './lists/categoria-plano-conta-list/categoria-plano-conta-list.component';
import { CategoriaPlanoContaFormComponent } from './forms/categoria-plano-conta-form/categoria-plano-conta-form.component';
import { PostoListComponent } from './lists/posto-list/posto-list.component';
import { ProdutoListComponent } from './lists/produto-list/produto-list.component';
import { ProdutoFormComponent } from './forms/produto-form/produto-form.component';
import { SelecionarProdutoFormComponent } from './forms/selecionar-produto-form/selecionar-produto-form.component';
import { UsuarioListComponent } from './lists/usuario-list/usuario-list.component';
import { UsuarioFormComponent } from './forms/usuario-form/usuario-form.component';
import { AlterarContratoFormComponent } from './forms/alterar-contrato-form/alterar-contrato-form.component';
import { TransferirResponsavelFormComponent } from './forms/transferir-responsavel-form/transferir-responsavel-form.component';
import { AnaliseRenovacaoContratoComponent } from './widgets/analise-renovacao-contrato/analise-renovacao-contrato.component';
import { CarouselArquivosContratoComponent } from './widgets/carousel-arquivos-contrato/carousel-arquivos-contrato.component';
import { TransferenciaRotaFormComponent } from './forms/transferencia-rota-form/transferencia-rota-form.component';
import { SelectFormComponent } from './forms/select-form/select-form.component';
import { ArquivoImportacaoNovoListComponent } from './lists/arquivo-importacao-novo-list/arquivo-importacao-novo-list.component';
import { ArquivoImportacaoFinalizadoListComponent } from './lists/arquivo-importacao-finalizado-list/arquivo-importacao-finalizado-list.component';
import { ArquivoAnaliseDeRiscoListComponent } from './lists/arquivo-analise-de-risco-list/arquivo-analise-de-risco-list.component';
import { ArquivoAnaliseDeRiscoFormComponent } from './forms/arquivo-analise-de-risco-form/arquivo-analise-de-risco-form.component';
import { AnaliseDeRiscoClienteListComponent } from './lists/analise-de-risco-cliente-list/analise-de-risco-cliente-list.component';
import { ParcelaDuplicadaListComponent } from './lists/parcela-duplicada-list/parcela-duplicada-list.component';
import { ReportMainComponent } from '../base/report/report-main/report-main.component';
import { ReportFiltersComponent } from '../base/report/report-filters/report-filters.component';
import { CurrencyFormComponent } from './forms/currency-form/currency-form.component';
import { AlterarPostoClienteFormComponent } from './forms/alterar-posto-cliente-form/alterar-posto-cliente-form.component';
import { AuditoriaConferenciaCaixaGeralListComponent } from './lists/auditoria-conferencia-caixa-geral-list/auditoria-conferencia-caixa-geral-list.component';
import { FaturamentoDashboardComponent } from './faturamento-dashboard/faturamento-dashboard.component';
import { MglTimelineModule } from 'angular-mgl-timeline';
import { AlterarResponsavelRotaFormComponent } from './forms/alterar-responsavel-rota-form/alterar-responsavel-rota-form.component';
import { AgendarAlteracaoResponsavelRotaFormComponent } from './forms/agendar-alteracao-responsavel-rota-form/agendar-alteracao-responsavel-rota-form.component';
import { AprovacaoDeCreditoListComponent } from './lists/aprovacao-de-credito-list/aprovacao-de-credito-list.component';
import { MatRadioModule } from '@angular/material/radio';
import { RealocarFuncionarioFormComponent } from './forms/realocar-funcionario-form/realocar-funcionario-form.component';
import { AcaoDeVendaListComponent } from './lists/acao-de-venda-list/acao-de-venda-list.component';
import { PlanejamentoSemanaCaptacaoListComponent } from './lists/planejamento-semana-captacao-list/planejamento-semana-captacao-list.component';
import { PlanejamentoSemanaCaptacaoFormComponent } from './forms/planejamento-semana-captacao-form/planejamento-semana-captacao-form.component';
import { CaptacaoFormComponent } from './forms/captacao-form/captacao-form.component';
import { EquipeVisitadorFormComponent } from './forms/equipe-visitador-form/equipe-visitador-form.component';
import { CobrancaDashboardComponent } from './cobranca-dashboard/cobranca-dashboard.component';
import { CrescimentoSustentavelDashboardComponent } from './crescimento-sustentavel-dashboard/crescimento-sustentavel-dashboard.component';
import { CarteiraClienteListComponent } from './lists/carteira-cliente-list/carteira-cliente-list.component';
import { CarteiraClienteFormComponent } from './forms/carteira-cliente-form/carteira-cliente-form.component';
import { ClienteFormComponent } from './forms/cliente-form/cliente-form.component';
import { UppercaseDirective } from '../shared/diretivas/uppercase.directive';
import { EditarTelefoneFormComponent } from './forms/editar-telefone-form/editar-telefone-form.component';
import { AlterarValorContratoFormComponent } from './forms/alterar-valor-contrato-form/alterar-valor-contrato-form.component';
import { ToggleButtonModule } from 'primeng';
import { CadastrarPequenaCausaFormComponent } from './forms/cadastrar-pequena-causa-form/cadastrar-pequena-causa-form.component';
import { CobrancaJudicialListComponent } from './lists/cobranca-judicial-list/cobranca-judicial-list.component';
import { ParcelaCobrancaJudicialListComponent } from './lists/parcela-cobranca-judicial-list/parcela-cobranca-judicial-list.component';
import { BaixaParcelaCobrancaJudicialFormComponent } from './forms/baixa-parcela-cobranca-judicial-form/baixa-parcela-cobranca-judicial-form.component';
import { ProrrogadoDashboardComponent } from './prorrogado-dashboard/prorrogado-dashboard.component';
import { DespesaDashboardComponent } from './despesa-dashboard/despesa-dashboard.component';
import { AcompanhamentoComprovantesPixListComponent } from './lists/acompanhamento-comprovantes-pix-list/acompanhamento-comprovantes-pix-list.component';
import { DebitosFuncionarioListComponent } from './lists/debitos-funcionario-list/debitos-funcionario-list.component';
import { ParcelaCobrancaValeListComponent } from './lists/parcela-cobranca-vale-list/parcela-cobranca-vale-list.component';
import { GerarParcelaFormComponent } from './forms/gerar-parcela-form/gerar-parcela-form.component';
import { FinalizarContratoComDividaComponent } from './forms/finalizar-contrato-com-divida/finalizar-contrato-com-divida.component';
import { RenegociarContratoFormComponent } from './forms/renegociar-contrato-form/renegociar-contrato-form.component';
import { ArquivoConciliacaoListComponent } from './lists/arquivo-conciliacao-list/arquivo-conciliacao-list.component';
import { AdicionarPromocaoComponent } from './forms/adicionar-promocao/adicionar-promocao.component';
import { VisualizarConciliacaoListComponent } from './lists/visualizar-conciliacao-list/visualizar-conciliacao-list.component';
import { VisualizarConciliacaoDocListComponent } from './lists/visualizar-conciliacao-doc-list/visualizar-conciliacao-doc-list.component';
import { VisualizarConciliacaoTevListComponent } from './lists/visualizar-conciliacao-tev-list/visualizar-conciliacao-tev-list.component';
import { ConciliacaoAssociacaoFormComponent } from './forms/conciliacao-associacao-form/conciliacao-associacao-form.component';
import { AlterarParcelaComponent } from './forms/alterar-parcela-contrato/alterar-parcela-contrato.component';
import { CorrigirPagamentoDiaFormComponent } from './forms/corrigir-pagamento-dia-form/corrigir-pagamento-dia-form.component';
import { AlterarValorCobrancaJudicialFormComponent } from './forms/alterar-valor-cobranca-judicial-form/alterar-valor-cobranca-judicial-form.component';
import { AlterarEmissaoCobrancaJudicialFormComponent } from './forms/alterar-emissao-cobranca-judicial-form/alterar-emissao-cobranca-judicial-form.component';
import { ArquivoExtratoListComponent } from './lists/arquivo-extrato-list/arquivo-extrato-list.component';
import { ContaTevListComponent } from './lists/conta-tev-list/conta-tev-list.component';
import { ContaTevFormComponent } from './forms/conta-tev-form/conta-tev-form.component';
import { VisualizarConciliacaoTedListComponent } from './lists/visualizar-conciliacao-ted-list/visualizar-conciliacao-ted-list.component';
import { ConciliacaoDashboardComponent } from './conciliacao-dashboard/conciliacao-dashboard.component';
import { ConciliacaoFormComponent } from './forms/conciliacao-form/conciliacao-form.component';
import { VisualizarConciliacaoDinheiroListComponent } from './lists/visualizar-conciliacao-dinheiro-list/visualizar-conciliacao-dinheiro-list.component';
import { VincularConciliacaoTedComponent } from './forms/vincular-conciliacao-ted/vincular-conciliacao-ted.component';
import { VincularConciliacaoDocComponent } from './forms/vincular-conciliacao-doc/vincular-conciliacao-doc.component';
import { ImportarArquivoConciliacaoBancariaFormComponent } from './forms/importar-arquivo-conciliacao-bancaria-form/importar-arquivo-conciliacao-bancaria-form.component';
import { AlterarParcelaCobrancaJudicialFormComponent } from './forms/alterar-parcela-cobranca-judicial/alterar-parcela-cobranca-judicial-form.component';
import { ParcelaChatbotListComponent } from './lists/parcela-chatbot-list/parcela-chatbot-list.component';

export const customCurrencyMaskConfig = {
  align: 'right',
  allowNegative: false,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  suffix: '',
  thousands: '.',
  nullable: true,
  min: 0,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL,
};

@NgModule({
  declarations: [
    ClientePlanejamentoListComponent,
    ClienteListComponent,
    CaixaListComponent,
    ContratoListComponent,
    ParcelaListComponent,
    TransferenciaListComponent,
    CaptadoListComponent,
    CaixaMovimentacoesComponent,
    ConfirmacaoDialogComponent,
    ContratoInfoGeralComponent,
    ClienteInfoGeralComponent,
    EnderecoFormComponent,
    ConciliacaoAssociacaoFormComponent,
    CancelarContratoFormComponent,
    GraficoVerticalComponent,
    GlobalFilterComponent,
    DesbloquearClienteFormComponent,
    CounterCardComponent,
    FeriadoListComponent,
    FeriadoFormComponent,
    ReagendamentoListComponent,
    CheckboxSelectFormComponent,
    DataValorFormComponent,
    RepactuacaoContratoFormComponent,
    LiquidarContratoFormComponent,
    PendenciaFechamentoListComponent,
    ConferirCaixaFormComponent,
    CurrencyFormComponent,
    CheckboxSelectFormComponent,
    LiquidarContratoFormComponent,
    AuditoriaAlterarParcelaMainComponent,
    AlterarParcelaListComponent,
    ContaClienteInfoComponent,
    EquipeRotaFormComponent,
    EquipeVisitadorFormComponent,
    CancelarSobraFormComponent,
    AuditoriaContratoListComponent,
    AuditoriaDespesaListComponent,
    EquipeListComponent,
    DespesaRouboFormComponent,
    RotaListComponent,
    CategoriaPlanoContaListComponent,
    CategoriaPlanoContaFormComponent,
    PostoListComponent,
    ProdutoListComponent,
    ProdutoFormComponent,
    SelecionarProdutoFormComponent,
    UsuarioListComponent,
    UsuarioFormComponent,
    AlterarContratoFormComponent,
    TransferirResponsavelFormComponent,
    AnaliseRenovacaoContratoComponent,
    CarouselArquivosContratoComponent,
    TransferenciaRotaFormComponent,
    SelectFormComponent,
    ImportarArquivoConciliacaoBancariaFormComponent,
    ArquivoImportacaoNovoListComponent,
    ArquivoImportacaoFinalizadoListComponent,
    ArquivoAnaliseDeRiscoListComponent,
    ArquivoAnaliseDeRiscoFormComponent,
    AnaliseDeRiscoClienteListComponent,
    ParcelaDuplicadaListComponent,
    AlterarPostoClienteFormComponent,
    ReportMainComponent,
    ReportFiltersComponent,
    AuditoriaConferenciaCaixaGeralListComponent,
    FaturamentoDashboardComponent,
    AlterarResponsavelRotaFormComponent,
    AgendarAlteracaoResponsavelRotaFormComponent,
    AprovacaoDeCreditoListComponent,
    RealocarFuncionarioFormComponent,
    AcaoDeVendaListComponent,
    FuncionarioListComponent,
    FuncionarioFormComponent,
    PlanejamentoSemanaCaptacaoListComponent,
    PlanejamentoSemanaCaptacaoFormComponent,
    CaptacaoFormComponent,
    CobrancaDashboardComponent,
    CrescimentoSustentavelDashboardComponent,
    CarteiraClienteListComponent,
    CarteiraClienteFormComponent,
    ClienteFormComponent,
    UppercaseDirective,
    EditarTelefoneFormComponent,
    AlterarValorContratoFormComponent,
    CadastrarPequenaCausaFormComponent,
    CobrancaJudicialListComponent,
    ParcelaCobrancaJudicialListComponent,
    BaixaParcelaCobrancaJudicialFormComponent,
    ProrrogadoDashboardComponent,
    DespesaDashboardComponent,
    AcompanhamentoComprovantesPixListComponent,
    DebitosFuncionarioListComponent,
    ParcelaCobrancaValeListComponent,
    GerarParcelaFormComponent,
    FinalizarContratoComDividaComponent,
    RenegociarContratoFormComponent,
    ArquivoConciliacaoListComponent,
    AdicionarPromocaoComponent,
    VisualizarConciliacaoListComponent,
    VisualizarConciliacaoDocListComponent,
    VisualizarConciliacaoTevListComponent,
    AlterarParcelaComponent,
    CorrigirPagamentoDiaFormComponent,
    AlterarValorCobrancaJudicialFormComponent,
    AlterarParcelaCobrancaJudicialFormComponent,
    AlterarEmissaoCobrancaJudicialFormComponent,
    ArquivoExtratoListComponent,
    ContaTevListComponent,
    ContaTevFormComponent,
    VisualizarConciliacaoTedListComponent,
    ConciliacaoDashboardComponent,
    ConciliacaoFormComponent,
    VisualizarConciliacaoDinheiroListComponent,
    VincularConciliacaoTedComponent,
    VincularConciliacaoDocComponent,
    ParcelaChatbotListComponent,
  ],
  providers: [
    DialogService,
    PostoService,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'DD/MM/YYYY',
        },
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-br' },
  ],
  exports: [
    ClientePlanejamentoListComponent,
    ClienteListComponent,
    CaixaListComponent,
    ContratoListComponent,
    ParcelaListComponent,
    TransferenciaListComponent,
    CaptadoListComponent,
    CaixaMovimentacoesComponent,
    ContratoInfoGeralComponent,
    ClienteInfoGeralComponent,
    EnderecoFormComponent,
    ConciliacaoAssociacaoFormComponent,
    GraficoVerticalComponent,
    GlobalFilterComponent,
    CounterCardComponent,
    CancelarContratoFormComponent,
    FeriadoListComponent,
    FeriadoFormComponent,
    ReagendamentoListComponent,
    CheckboxSelectFormComponent,
    DataValorFormComponent,
    RepactuacaoContratoFormComponent,
    LiquidarContratoFormComponent,
    PendenciaFechamentoListComponent,
    ConferirCaixaFormComponent,
    CheckboxSelectFormComponent,
    LiquidarContratoFormComponent,
    AuditoriaAlterarParcelaMainComponent,
    AlterarParcelaListComponent,
    ContaClienteInfoComponent,
    EquipeRotaFormComponent,
    EquipeVisitadorFormComponent,
    CancelarSobraFormComponent,
    AuditoriaContratoListComponent,
    AuditoriaDespesaListComponent,
    EquipeListComponent,
    DespesaRouboFormComponent,
    RotaListComponent,
    CategoriaPlanoContaListComponent,
    CategoriaPlanoContaFormComponent,
    PostoListComponent,
    ProdutoListComponent,
    ProdutoFormComponent,
    SelecionarProdutoFormComponent,
    UsuarioListComponent,
    UsuarioFormComponent,
    AlterarContratoFormComponent,
    TransferirResponsavelFormComponent,
    AnaliseRenovacaoContratoComponent,
    CarouselArquivosContratoComponent,
    TransferenciaRotaFormComponent,
    SelectFormComponent,
    ArquivoImportacaoNovoListComponent,
    ArquivoImportacaoFinalizadoListComponent,
    ArquivoAnaliseDeRiscoListComponent,
    ArquivoAnaliseDeRiscoFormComponent,
    AnaliseDeRiscoClienteListComponent,
    ParcelaDuplicadaListComponent,
    ReportMainComponent,
    ReportFiltersComponent,
    AlterarPostoClienteFormComponent,
    AuditoriaConferenciaCaixaGeralListComponent,
    FaturamentoDashboardComponent,
    MglTimelineModule,
    AlterarResponsavelRotaFormComponent,
    AgendarAlteracaoResponsavelRotaFormComponent,
    AprovacaoDeCreditoListComponent,
    RealocarFuncionarioFormComponent,
    AcaoDeVendaListComponent,
    FuncionarioListComponent,
    FuncionarioFormComponent,
    PlanejamentoSemanaCaptacaoListComponent,
    PlanejamentoSemanaCaptacaoFormComponent,
    CaptacaoFormComponent,
    CobrancaDashboardComponent,
    CrescimentoSustentavelDashboardComponent,
    CarteiraClienteListComponent,
    CarteiraClienteFormComponent,
    ClienteFormComponent,
    EditarTelefoneFormComponent,
    AlterarValorContratoFormComponent,
    CadastrarPequenaCausaFormComponent,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    CobrancaJudicialListComponent,
    ParcelaCobrancaJudicialListComponent,
    BaixaParcelaCobrancaJudicialFormComponent,
    ProrrogadoDashboardComponent,
    DespesaDashboardComponent,
    AcompanhamentoComprovantesPixListComponent,
    DebitosFuncionarioListComponent,
    ParcelaCobrancaValeListComponent,
    GerarParcelaFormComponent,
    FinalizarContratoComDividaComponent,
    RenegociarContratoFormComponent,
    ArquivoConciliacaoListComponent,
    AdicionarPromocaoComponent,
    VisualizarConciliacaoListComponent,
    VisualizarConciliacaoDocListComponent,
    VisualizarConciliacaoTevListComponent,
    VisualizarConciliacaoTedListComponent,
    AlterarParcelaComponent,
    CorrigirPagamentoDiaFormComponent,
    AlterarValorCobrancaJudicialFormComponent,
    AlterarEmissaoCobrancaJudicialFormComponent,
    ArquivoExtratoListComponent,
    ContaTevListComponent,
    ContaTevFormComponent,
    ConciliacaoDashboardComponent,
    ConciliacaoFormComponent,
    VisualizarConciliacaoDinheiroListComponent,
    VincularConciliacaoTedComponent,
    VincularConciliacaoDocComponent,
    AlterarParcelaCobrancaJudicialFormComponent,
    ParcelaChatbotListComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    ComponentsModule,
    TableModule,
    PaginatorModule,
    TabViewModule,
    MatMenuModule,
    MatDialogModule,
    MatSelectModule,
    MatInputModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot({ validation: true }),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatTabsModule,
    MglTimelineModule,
    MatRadioModule,
    ToggleButtonModule,
  ],
})
export class GlobalModule {}
