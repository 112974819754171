import { EstadoSelectDTO } from './domain/dto/estado-select.dto';
import { PostoSelectDTO } from './domain/dto/posto-select.dto';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BaseRoutes } from './base.routing';
import { AcompanhamentoPendenciaModule } from './caixas/acompanhamento-pendencia/acompanhamento-pendencia.module';
import { CaixaControleModule } from './caixas/caixa-controle/caixa-controle.module';
import { CaixaDespesaFuncionarioModule } from './caixas/caixa-despesa-funcionario/caixa-despesa-funcionario.module';
import { CaixaFuncionarioModule } from './caixas/caixa-funcionario/caixa-funcionario.module';
import { CaixaGeralModule } from './caixas/caixa-geral/caixa-geral.module';
import { AcoesDeVendaModule } from './cliente/acoes-de-venda/acoes-de-venda.module';
import { AcompanhamentoClienteModule } from './cliente/acompanhamento-cliente/acompanhamento-cliente.module';
import { PlanejamentoClienteModule } from './cliente/planejamento-cliente/planejamento-cliente.module';
import { ConciliacaoModule } from './conciliacao/conciliacao.module';
import { AcompanhamentoContratoModule } from './contratos/acompanhamento-contrato/acompanhamento-contrato.module';
import { ContratosACancelarModule } from './contratos/contratos-a-cancelar/contratos-a-cancelar.module';
import { AcompanhamentoComprovantesPixModule } from './gestao/acompanhamento-comprovantes-pix/acompanhamento-comprovantes-pix.module';
import { AcompanhamentoDespesaModule } from './gestao/acompanhamento-despesa/acompanhamento-despesa.module';
import { AcompanhamentoFeriadoModule } from './gestao/acompanhamento-feriado/acompanhamento-feriado.module';
import { AcompanhamentoReagendamentoModule } from './gestao/acompanhamento-reagendamento/acompanhamento-reagendamento.module';
import { AcompanhamentoSangriaModule } from './gestao/acompanhamento-sangria/acompanhamento-sangria.module';
import { ContratacaoCaptacaoModule } from './gestao/contratacao-captacao/contratacao-captacao.module';
import { CrescimentoSustentavelModule } from './gestao/crescimento-sustentavel/crescimento-sustentavel.module';
import { PendenciaFechamentoCaixaModule } from './gestao/pendencia-fechamento-caixa/pendencia-fechamento-caixa.module';
import { VisitacaoCaptacaoModule } from './gestao/visitacao-captacao/visitacao-captacao.module';
import { PessoaModule } from './pessoa/pessoa.module';
import { ArquivoVisitaService } from './services/arquivo-visita.service';
import { AuditoriaAlterarParcelaService } from './services/auditoria-alterar-parcela.service';
import { CaixaService } from './services/caixa.service';
import { CaptadoService } from './services/captado.service';
import { CargoService } from './services/cargo.service';
import { ClientePlanejamentoService } from './services/cliente-planejamento.service';
import { ClienteService } from './services/cliente.service';
import { ContratoService } from './services/contrato.service';
import { DashBoardCobrancaService } from './services/dashboard-cobranca.service';
import { DashboardCrescimentoSustentavelService } from './services/dashboard-crescimento-sustentavel.service';
import { EstadoService } from './services/estado.service';
import { FeriadoService } from './services/feriado.service';
import { FuncionarioService } from './services/funcionario.service';
import { PendenciaFechamentoCaixaService } from './services/pendencia-fechamento-caixa.service';
import { PessoaService } from './services/pessoa.service';
import { PostoService } from './services/posto.service';
import { PublicService } from './services/public.service';
import { ReagendamentoService } from './services/reagendamento.service';
import { TransferenciaService } from './services/transferencia.service';
import { UserService } from './services/user.service';
import { VisitaService } from './services/visita.service';

import { MatSelectModule } from '@angular/material/select';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { AuditoriaContratoModule } from './auditorias/auditoria-contrato/auditoria-contrato.module';
import { AuditoriaDespesaModule } from './auditorias/auditoria-despesa/auditoria-despesa.module';
import { CaixaCobrancaModule } from './caixas/caixa-cobranca/caixa-cobranca.module';
import { AcompanhamentoCaptacaoModule } from './captacao/acompanhamento-captacao/acompanhamento-captacao.module';
import { PlanejamentoSemanaCaptacaoModule } from './captacao/planejamento-semana-captacao/planejamento-semana-captacao.module';
import { AcompanhamentosRepassesPeriodoModule } from './cliente/acompanhamentos-repasses-periodo/acompanhamentos-repasses-periodo.module';
import { AnaliseDeRiscoClienteModule } from './cliente/analise-de-risco-cliente/analise-de-risco-cliente.module';
import { ArquivoAnaliseDeRiscoModule } from './cliente/arquivo-analise-de-risco/arquivo-analise-de-risco.module';
import { ClienteCobrancaJudicialModule } from './cliente/cliente-cobranca-judicial/cliente-cobranca-judicial.module';
import { ClientesInativosModule } from './cliente/clientes-inativos/clientes-inativos.module';
import { CobrancaJudicialModule } from './cliente/cobranca-judicial/cobranca-judicial.module';
import { PotencialClienteModule } from './cliente/potencial-cliente/potencial-cliente.module';
import { VerificacaoClienteModule } from './cliente/verificacao-cliente/verificacao-cliente.module';
import { ProdutoModule } from './configuracao/produto/produto.module';
import { UsuarioModule } from './configuracao/usuario/usuario.module';
import { ContratosEmFinalizacaoModule } from './contratos/contratos-em-finalizacao/contratos-em-finalizacao.module';
import { ContratosRenegociarModule } from './contratos/contratos-renegociar/contratos-renegociar.module';
import { DebitosFuncionarioModule } from './contratos/debitos-funcionario/debitos-funcionario.module';
import { AcompanhamentoCaixasPostosModule } from './gestao/acompanhamento-caixas-postos/acompanhamento-caixas-postos.module';
import { AcompanhamentoCasosRouboModule } from './gestao/acompanhamento-casos-roubo/acompanhamento-casos-roubo.module';
import { AcompanhamentoCobrancaModule } from './gestao/acompanhamento-cobranca/acompanhamento-cobranca.module';
import { AcompanhamentoConferenciaCaixaGeralModule } from './gestao/acompanhamento-conferencia-caixa-geral/acompanhamento-conferencia-caixa-geral.module';
import { AcompanhamentoDespesaAdministrativaModule } from './gestao/acompanhamento-despesa-administrativa/acompanhamento-despesa-administrativa.module';
import { AcompanhamentoFaturamentoDigitalModule } from './gestao/acompanhamento-faturamento-digital/acompanhamento-faturamento-digital.module';
import { AcompanhamentoFaturamentoNacionalModule } from './gestao/acompanhamento-faturamento-nacional/acompanhamento-faturamento-nacional.module';
import { AcompanhamentoFaturamentoModule } from './gestao/acompanhamento-faturamento/acompanhamento-faturamento.module';
import { AcompanhamentoParcelaDuplicadaModule } from './gestao/acompanhamento-parcela-duplicada/acompanhamento-parcela-duplicada.module';
import { AcompanhamentoProrrogadoModule } from './gestao/acompanhamento-prorrogado/acompanhamento-prorrogado.module';
import { AcompanhamentoSangriaGeralModule } from './gestao/acompanhamento-sangria-geral/acompanhamento-sangria-geral.module';
import { AcompanhamentoSangriaPostoModule } from './gestao/acompanhamento-sangria-posto/acompanhamento-sangria-posto.module';
import { AcompanhamentoSobraModule } from './gestao/acompanhamento-sobra/acompanhamento-sobra.module';
import { AcompanhamentoTransferenciaEntrePostosModule } from './gestao/acompanhamento-transferencia-entre-postos/acompanhamento-transferencia-entre-postos.module';
import { AcompanhamentoVendaModule } from './gestao/acompanhamento-venda/acompanhamento-venda.module';
import { AcompanhamentoVendedorModule } from './gestao/acompanhamento-vendedor/acompanhamento-vendedor.module';
import { AprovacaoDeCreditoModule } from './gestao/aprovacao-de-credito/aprovacao-de-credito.module';
import { CategoriaPlanoContaModule } from './gestao/categoria-plano-conta/categoria-plano-conta.module';
import { CobrancaClienteRegularFdsModule } from './gestao/cobranca-cliente-regular-fds/cobranca-cliente-regular-fds.module';
import { EquipeModule } from './gestao/equipes/equipes.module';
import { MonitoramentoLancamentoEntradaModule } from './gestao/monitoramento-lancamento-entrada/monitoramento-lancamento-entrada.module';
import { MonitoramentoRepasseTipoClienteModule } from './gestao/monitoramento-repasse-tipo-cliente/monitoramento-repasse-tipo-cliente.module';
import { MonitoramentoRepassesModule } from './gestao/monitoramento-repasses/monitoramento-repasses.module';
import { MotivoContratoCanceladoModule } from './gestao/motivo-contrato-cancelado/motivo-contrato-cancelado.module';
import { OperadoresExternosModule } from './gestao/operadores-externos/operadores-externos.module';
import { RealocarFuncionarioModule } from './gestao/realocar-funcionario/realocar-funcionario.module';
import { IntegracaoModule } from './integracao-rbm/integracao.module';
import { AcaoDeVendaService } from './services/acao-de-venda.service';
import { AcompanhamentoVendaService } from './services/acompanhamento-venda.service';
import { AnaliseDeRiscoClienteService } from './services/analise-de-risco-cliente.service';
import { AreaAtuacaoService } from './services/area-atuacao.service';
import { ArquivoAnaliseDeRiscoService } from './services/arquivo-analise-de-risco.service';
import { ArquivoComprovantePixService } from './services/arquivo-comprovante-pix.service';
import { ArquivoConciliacaoBancariaService } from './services/arquivo-conciliacao-bancaria.service';
import { ArquivoImportacaoFinalizadoService } from './services/arquivo-importacao-finalizado.service ';
import { ArquivoImportacaoNovoService } from './services/arquivo-importacao-novo.service';
import { AtividadeComercialService } from './services/atividade-comercial.service';
import { AuditoriaConferenciaCaixaGeralService } from './services/auditoria-conferencia-caixa-geral.service';
import { AuditoriaContratoService } from './services/auditoria-contrato.service';
import { AuditoriaDespesaService } from './services/auditoria-despesa.service';
import { AuditoriaTransferenciaClienteService } from './services/auditoria-transferencia-cliente.service';
import { CarteiraClienteService } from './services/carteira-cliente.service';
import { CategoriaPlanoContaService } from './services/categoria-plano-conta.service';
import { CobrancaJudicialService } from './services/cobranca-judicial.service';
import { CobrancaValeService } from './services/cobranca-vale.service';
import { ContratoComissaoService } from './services/contrato-comissao.service';
import { DashBoardDespesaService } from './services/dashboard-despesa.service';
import { DashBoardFaturamentoService } from './services/dashboard-faturamento.service';
import { DashBoardProrrogadoService } from './services/dashboard-prorrogado.service';
import { EquipeService } from './services/equipe.service';
import { ParcelaCobrancaJudicialService } from './services/parcela-cobranca-judicial.service';
import { ParcelaCobrancaValeService } from './services/parcela-cobranca-vale.service';
import { PlanejamentoSemanaCaptacaoService } from './services/planejamento-semana-captacao.service';
import { ProdutoService } from './services/produto.service';
import { RotaService } from './services/rota.service';
import { ArquivoExtratoModule } from './arquivo-extrato/arquivo-extrato.module';
import { AcompanhamentoFaturamentoBoletoModule } from './gestao/acompanhamento-faturamento-boleto/faturamento-boleto.module';
import { ParcelaChatbotComponent } from './parcela-chatbot/parcela-chatbot.component';
import { ParcelaChatbotService } from './services/parcela-chatbot.service';
import { ParcelaChatbotModule } from './parcela-chatbot/parcela-chatbot.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(BaseRoutes),
    PessoaModule,
    CaixaControleModule,
    AcompanhamentoContratoModule,
    PlanejamentoClienteModule,
    ContratosACancelarModule,
    ContratacaoCaptacaoModule,
    ContratosEmFinalizacaoModule,
    AcoesDeVendaModule,
    AcompanhamentoClienteModule,
    VisitacaoCaptacaoModule,
    CaixaFuncionarioModule,
    AcompanhamentoSangriaModule,
    AcompanhamentoFeriadoModule,
    AcompanhamentoReagendamentoModule,
    PendenciaFechamentoCaixaModule,
    CaixaGeralModule,
    CaixaDespesaFuncionarioModule,
    AcompanhamentoPendenciaModule,
    AcompanhamentoSobraModule,
    AuditoriaContratoModule,
    AuditoriaDespesaModule,
    EquipeModule,
    AcompanhamentoCasosRouboModule,
    CategoriaPlanoContaModule,
    AcompanhamentoDespesaAdministrativaModule,
    AcompanhamentoTransferenciaEntrePostosModule,
    AcompanhamentoCaixasPostosModule,
    AcompanhamentoSangriaPostoModule,
    ProdutoModule,
    UsuarioModule,
    AcompanhamentosRepassesPeriodoModule,
    ClientesInativosModule,
    IntegracaoModule,
    ArquivoAnaliseDeRiscoModule,
    AnaliseDeRiscoClienteModule,
    AcompanhamentoParcelaDuplicadaModule,
    MatSelectModule,
    AcompanhamentoSangriaGeralModule,
    AcompanhamentoConferenciaCaixaGeralModule,
    AcompanhamentoFaturamentoModule,
    AcompanhamentoVendaModule,
    MotivoContratoCanceladoModule,
    AprovacaoDeCreditoModule,
    RealocarFuncionarioModule,
    PotencialClienteModule,
    OperadoresExternosModule,
    PlanejamentoSemanaCaptacaoModule,
    AcompanhamentoCaptacaoModule,
    MonitoramentoRepassesModule,
    CobrancaClienteRegularFdsModule,
    MonitoramentoLancamentoEntradaModule,
    MonitoramentoRepasseTipoClienteModule,
    AcompanhamentoCobrancaModule,
    CrescimentoSustentavelModule,
    AcompanhamentoVendedorModule,
    CaixaCobrancaModule,
    ToggleButtonModule,
    VerificacaoClienteModule,
    ClienteCobrancaJudicialModule,
    CobrancaJudicialModule,
    AcompanhamentoFaturamentoDigitalModule,
    AcompanhamentoFaturamentoNacionalModule,
    AcompanhamentoProrrogadoModule,
    AcompanhamentoDespesaModule,
    AcompanhamentoComprovantesPixModule,
    DebitosFuncionarioModule,
    ContratosRenegociarModule,
    ConciliacaoModule,
    ArquivoExtratoModule,
    AcompanhamentoFaturamentoBoletoModule,
    ParcelaChatbotModule,
  ],
  providers: [
    EstadoService,
    PessoaService,
    UserService,
    CaixaService,
    TransferenciaService,
    ContratoService,
    PublicService,
    VisitaService,
    PostoService,
    ClientePlanejamentoService,
    CaptadoService,
    ClienteService,
    FuncionarioService,
    FeriadoService,
    ReagendamentoService,
    AuditoriaAlterarParcelaService,
    PendenciaFechamentoCaixaService,
    AuditoriaContratoService,
    AuditoriaDespesaService,
    EquipeService,
    RotaService,
    CategoriaPlanoContaService,
    ProdutoService,
    AreaAtuacaoService,
    ArquivoImportacaoNovoService,
    ArquivoImportacaoFinalizadoService,
    ArquivoAnaliseDeRiscoService,
    AnaliseDeRiscoClienteService,
    AuditoriaConferenciaCaixaGeralService,
    DashBoardFaturamentoService,
    AcompanhamentoVendaService,
    ArquivoVisitaService,
    AcaoDeVendaService,
    CargoService,
    PlanejamentoSemanaCaptacaoService,
    AtividadeComercialService,
    DashBoardCobrancaService,
    DashboardCrescimentoSustentavelService,
    CarteiraClienteService,
    CobrancaJudicialService,
    ParcelaCobrancaJudicialService,
    DashBoardProrrogadoService,
    DashBoardDespesaService,
    ContratoComissaoService,
    AuditoriaTransferenciaClienteService,
    ArquivoComprovantePixService,
    CobrancaValeService,
    ParcelaCobrancaValeService,
    ArquivoConciliacaoBancariaService,
    ParcelaChatbotService,
  ],
  exports: [PessoaModule, PublicService],
  declarations: [],
})
export class BaseModule {}
